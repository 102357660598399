import React from 'react';
import {Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import {IOpenHoursOutput, Translation} from 'reservation-common-web';
import DatePicker from 'react-datepicker';
import {useDispatch, useSelector} from 'react-redux';
import {setOpeningHours} from '../../../../store/reducers/restaurantSlice';
import {openingHoursSelector} from '../../../../store/selectors/restaurantSelector';

const parseDatepickerTime = (time: string | undefined): Date | null => {
    if (!time) {
        return null;
    }
    const [hours, minutes] = time.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes, 0, 0);
    return date;
};
const formatTimeToHHmm = (date: Date): string => {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
};

const OpeningHoursContent: React.FC = () => {
    const daysOfWeek = ['_0', '_1', '_2', '_3', '_4', '_5', '_6'];
    const timeValidationSchema = Yup.object({
        from: Yup.string()
            .required('Required')
            .test('is-later', 'Opening time must be earlier than closing time', function (value) {
                return value <= this.parent.to;
            }),
        to: Yup.string()
            .required('Required')
            .test('is-earlier', 'Closing time must be later than opening time', function (value) {
                return value >= this.parent.from;
            }),
    }).nullable();

    const validationSchema = Yup.object(
        daysOfWeek.reduce((acc: Record<string, Yup.ObjectSchema<{from: string; to: string} | null>>, day: string) => {
            acc[day] = timeValidationSchema as Yup.ObjectSchema<{from: string; to: string} | null>;
            return acc;
        }, {})
    );

    const valuesFromState = useSelector(openingHoursSelector);
    const initialValues: IOpenHoursOutput = {
        _0: valuesFromState?._0 ? valuesFromState._0 : {from: '08:00', to: '15:00'},
        _1: valuesFromState?._1 ? valuesFromState._1 : {from: '08:00', to: '15:00'},
        _2: valuesFromState?._2 ? valuesFromState._2 : {from: '08:00', to: '15:00'},
        _3: valuesFromState?._3 ? valuesFromState._3 : {from: '08:00', to: '15:00'},
        _4: valuesFromState?._4 ? valuesFromState._4 : {from: '08:00', to: '15:00'},
        _5: valuesFromState?._5 ? valuesFromState._5 : {from: '08:00', to: '15:00'},
        _6: valuesFromState?._6 ? valuesFromState._6 : {from: '08:00', to: '15:00'},
    };
    const dispatch = useDispatch();
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                const payload = {
                    openHours: values,
                };
                dispatch(setOpeningHours(payload));
            }}>
            {({values, setFieldValue, touched, errors, submitForm}) => (
                <Form>
                    {daysOfWeek.map((day) => (
                        <div key={day} className="form-control switch week-day">
                            <label className="switch-label">
                                <Field
                                    type="checkbox"
                                    name={day}
                                    checked={!!values[day as keyof IOpenHoursOutput]}
                                    onChange={() => {
                                        const isEnabled = !!values[day as keyof IOpenHoursOutput];
                                        setFieldValue(day, isEnabled ? '' : {from: '15:00', to: '15:00'});
                                        submitForm();
                                    }}
                                    className="switch-input"
                                />
                                <div className="switch-slider" />
                                <span className="switch-label-text">
                                    <Translation text={`form_page.opening_hours.weekdays.${day}`} />
                                </span>
                            </label>
                            {values[day as keyof IOpenHoursOutput] ? (
                                <div className="time-controls-wrapper">
                                    <div
                                        className={`form-control timepicker ${
                                            touched[day as keyof IOpenHoursOutput] && errors[day as keyof IOpenHoursOutput] ? 'invalid' : ''
                                        } ${values[day as keyof IOpenHoursOutput]?.from ? 'filled' : ''}`}
                                        key={`${day}.from`}>
                                        <DatePicker
                                            selected={parseDatepickerTime(values[day as keyof IOpenHoursOutput]?.from)}
                                            onChange={(val: any) => {
                                                console.log('val', val);
                                                const formattedTime = formatTimeToHHmm(val);
                                                setFieldValue(`${day}.from`, formattedTime);
                                                submitForm();
                                            }}
                                            showTimeSelect
                                            showPopperArrow={false}
                                            popperClassName="datepicker-popper"
                                            popperPlacement="bottom-start"
                                            showTimeSelectOnly
                                            timeIntervals={30}
                                            timeCaption="Time"
                                            timeFormat="HH:mm"
                                            dateFormat="HH:mm"
                                            disabled={false}
                                            customInput={
                                                <input
                                                    id={`${day}.from`}
                                                    name={`${day}.from`}
                                                    className={`form-input timepicker ${
                                                        values[day as keyof IOpenHoursOutput]?.from ? 'filled' : ''
                                                    }`}
                                                />
                                            }
                                        />
                                        <label htmlFor={`${day}.from`} className="form-label">
                                            <Translation text="form_page.opening_hours.openingHours" />{' '}
                                            <span className="label-required">*</span>
                                        </label>

                                        <ErrorMessage name={`${day}.from`} component="div" className="error-message" />
                                    </div>

                                    <div
                                        className={`form-control timepicker ${
                                            touched[day as keyof IOpenHoursOutput] && errors[day as keyof IOpenHoursOutput] ? 'invalid' : ''
                                        } ${values[day as keyof IOpenHoursOutput]?.to ? 'filled' : ''}`}
                                        key={`${day}.to`}>
                                        <DatePicker
                                            selected={parseDatepickerTime(values[day as keyof IOpenHoursOutput]?.to)}
                                            onChange={(val: any) => {
                                                const formattedTime = formatTimeToHHmm(val);
                                                setFieldValue(`${day}.to`, formattedTime);
                                                submitForm();
                                            }}
                                            showTimeSelect
                                            showPopperArrow={false}
                                            popperClassName="datepicker-popper"
                                            popperPlacement="bottom-start"
                                            showTimeSelectOnly
                                            timeIntervals={30}
                                            timeCaption="Time"
                                            timeFormat="HH:mm"
                                            dateFormat="HH:mm"
                                            disabled={false}
                                            customInput={
                                                <input
                                                    id={`${day}.to`}
                                                    name={`${day}.to`}
                                                    className={`form-input timepicker ${
                                                        values[day as keyof IOpenHoursOutput]?.to ? 'filled' : ''
                                                    }`}
                                                />
                                            }
                                        />
                                        <label htmlFor={`${day}.to`} className="form-label">
                                            <Translation text="form_page.opening_hours.openingHours" />{' '}
                                            <span className="label-required">*</span>
                                        </label>
                                        <ErrorMessage name={`${day}.to`} component="div" className="error-message" />
                                    </div>
                                </div>
                            ) : (
                                <p className="not-available-info">
                                    <Translation text="form_page.opening_hours.closed" />
                                </p>
                            )}
                        </div>
                    ))}
                </Form>
            )}
        </Formik>
    );
};

export default OpeningHoursContent;
