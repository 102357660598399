import {FormikHelpers} from 'formik';
import React from 'react';
import { ImageType } from './common';

export type IDatePickerInputProps = {
    value: string;
    name: string;
    id: string;
    placeholderText: string;
    disabledKeyboardNavigation: boolean;
    handleChange: (e: any) => void;
    inputStyles?: string;
    disabled?: boolean;
    maxDate?: string | Date;
    minDate?: string | Date;
    openToDate?: string | Date;
    minLength?: number;
    maxLength?: number;
    pattern?: string;
    onChange: (e: any) => void;
    onChangeRaw: (e: any) => void;
    onSelect: (e: any) => void;
    showMonthDropdown?: boolean;
    showYearDropdown?: boolean;
    inline?: boolean;
    dateFormat?: string;
    popperPlacement?: any;
};

export type IFileUploadListElement = {
    name: string;
    fileKey: string;
    url: string;
};

export enum FormikFieldTypes {
    TEXT = 'text',
    TEXTAREA = 'textarea',
    PASSWORD = 'password',
    NUMBER = 'number',
    EMAIL = 'email',
    SELECT = 'select',
    DATE = 'date',
    TIME = 'time',
    FILE = 'file',
    MASKED = 'masked',
    CUSTOM_CHECKBOX = 'customCheckbox',
    SUBMIT_BUTTON = 'submitButton',
    SWITCH = 'switch',
    CHECKBOX = 'checkbox',
    CUSTOM = 'custom',
    RICH_TEXT = 'richText',
    COLORPICKER = 'colorpicker',
}

export interface IMultiselectOption {
    label: string;
    value: string;
}
export interface CustomFieldProps {
    name: string;
    label: string;
    placeholder?: string;
    disabled?: boolean;
    options?: any[];
    required?: boolean;
}

export interface FormikGroupFieldConfig {
    formikControlType: FormGeneratorControlType;
    fields: FormikFieldConfig[];
    controlsPerRow?: number;
}


export interface FormikFieldConfig {
    name: string;
    label: string;
    validation: any;
    type: FormikFieldTypes;
    disabled?: boolean;
    options?: any;
    className?: string;
    autofocus?: boolean;
    placeholder?: string;
    multipleFiles?: boolean;
    acceptedFileTypes?: string;
    disablePast?: boolean;
    onFileChange?: (file: IFileUploadListElement, name: string) => void;
    getFileIdValue?: boolean;
    maxRows?: number;
    characterLimit?: number;
    isRequired?: boolean;
    sublabel?: string;
    maxDate?: Date | string;
    minDate?: Date | string;
    minDateTime?: Date | string | number;
    startFromTomorrow?: boolean;
    dateFormat?: string;
    customContent?: React.ReactNode;
    formControlClassName?: string;
    maxNumberLength?: number;
    isSearchable?: boolean;
    isComponentCustom?: boolean;
    customSelectOption?: React.FC<any>;
    customSelectValue?: React.FC<any>;
    customElement?: React.ReactNode;
    isLabelWithLink?: boolean;
    linkAction?: () => void;
    fileUploadRequirements?: {
        extensions?: ImageType[];
        size?: number;
        height?: number;
        width?: number;
    };
    }

export const FormikRegexSettings = {
    EMAIL: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
    NIP: /^[0-9]{10}$/,
    POSTAL_CODE: /^[0-9]{2}-[0-9]{3}$/,
    PHONE: /^[0-9]{9}$/,
    PASSWORD: /^(.*(^(?=.*[a-z])(?=.*[0-9]|.*[*.!@$%^&(){}\[\]:;<>,?/~_+-=|]).{8,}$).*)$/,
} as const;

export enum FormGeneratorControlType {
    CONTROL = 'control',
    GROUP_CONTROL = 'group_control',
}

export interface FormGeneratorConfig {
    formId: string;
    fields: FormikFieldConfig[];
    initialValues: any;
    onSubmit: (values: any, formikHelpers?: FormikHelpers<any>) => void;
    submitButtonLabel?: string;
    submitButtonClasses?: string;
    buttonContainerClasses?: string;
    submitAllowed?: (submitAllowed: boolean) => void;
    onChange?: (values: any) => void;
    debouncedChangeTimeout?: number;
    formClassName?: string;
    isDirty?: boolean;
}
