const en = {
    app: {
        title: 'Reservation Spot',
    },
    navbar: {
        app_title: 'Reservation Spot',
        app_subtitle: 'Just reserve it!',
        reservations: 'Rezerwacje',
        form: 'Formularz',
        go_premium: 'Go Premium',
    },
    form: {
        labels: {
            checkbox: `Zapoznałem się i akceptuję <a href="https://example.com/regulamin" target="_blank">Regulamin</a> oraz <a href="https://example.com/polityka" target="_blank">Politykę Prywatności</a>`,
        },
    },
    auth: {
        content: {
            description:
                'A professional kit that comes with ready-to-use MUI components developed with one common goal in mind, help you build faster & beautiful applications.',
        },
        login: {
            title: 'Log in',
            description: 'Nie masz jeszcze konta? <0>Zarejestruj się</0>',
            form: {
                labels: {
                    email: 'Email',
                    password: 'Hasło',
                },
            },
            buttons: {
                login: 'Login',
                reset_password: 'Reset hasła',
            },
        },
        register: {
            title: 'Rejestracja',
            description: 'Posiadasz już konto? <0>Zaloguj się</0>',
            form: {
                labels: {
                    plan: 'Plan',
                    email: 'Email',
                    password: 'Hasło',
                    repeat_password: 'Powtórz hasło',
                    terms_and_conditions: `Zapoznałem się i akceptuję <a href="https://example.com/regulamin" target="_blank">Regulamin</a> oraz <a href="https://example.com/polityka" target="_blank">Politykę Prywatności</a>`,
                },
                plan_options: {
                    free: 'Free',
                },
            },
            buttons: {
                register: 'Zarejestruj się',
            },
        },
        reset_password: {
            title: 'Reset hasła',
            description: 'Jednak pamiętasz hasło? ;) <0>Zaloguj się</0>',
            form: {
                labels: {
                    email: 'Email',
                },
            },
            buttons: {
                sendEmail: 'Wyślij link',
                back: 'Powrót',
            },
        },
        confirm_registration: {
            title: 'Potwierdź rejestrację',
            description: 'Aby zakończyć rejestrację na platformie kliknij przycisk poniżej',
            buttons: {
                confirm: 'Potwierdź',
            },
        },
        change_password: {
            title: 'Resetowanie hasła',
            description: 'Wprowadź nowe hasło.',
            form: {
                labels: {
                    password: 'Hasło',
                    repeat_password: 'Powtórz hasło',
                },
            },
            buttons: {
                change_password: 'Ustaw nowe hasło',
            },
        },
        alerts: {
            invalid_confirm_registration_url: 'Nieprawidłowy link potwierdzający rejestrację.',
            invalid_reset_password_url: 'Nieprawidłowy link do zmiany hasła.',
        },
    },
    reservations_page: {
        title: 'Rezerwacje',
        table_headers: {
            contact: 'Kontakt',
            room: 'Sala',
            number_of_people: 'Liczba osób',
            time: 'Godzina',
            prepayment: 'Przedpłata',
            message: 'Wiadomość',
        },
        print: 'Drukuj',
        find_reservation: 'Znajdź rezerwację',
        room: 'Sala',
        date: 'Data',
    },
    form_page: {
        form: 'Formularz',
        room_details: {
            title: 'Lokal',
            form: {
                labels: {
                    name: 'Nazwa',
                    address: 'Adres',
                    email: 'E-mail kontaktowy',
                    phone: 'Telefon kontaktowy',
                    privacy_policy: 'Polityka prywatności',
                },
            },
        },
        opening_hours: {
            title: 'Godziny otwarcia',
            openingHours: 'Otwarcie',
            closingHours: 'Zamknięcie',
            buttons: {
                edit: 'Edytuj',
                save: 'Zapisz',
                accept: 'Zatwierdź',
            },
            closed: 'Zamknięte',
            weekdays: {
                _0: 'Poniedziałek',
                _1: 'Wtorek',
                _2: 'Środa',
                _3: 'Czwartek',
                _4: 'Piątek',
                _5: 'Sobota',
                _6: 'Niedziela',
            },
        },
        reservations: {
            title: 'Rezerwacje',
            form: {
                labels: {
                    max_people_in_reservation: 'Maksymalna liczba ludzi w wszystkich rezerwacjach',
                    max_people_in_single_reservation: 'Maksymalna liczba ludzi w pojedynczej rezerwacji',
                    min_reservation_hour: 'Min godzina rozpoczęcia rezerwacji',
                    max_reservation_hour: 'Max godzina rozpoczęcia rezerwacji',
                    reservation_interval: 'Interwał rezerwacji',
                    prepayment_for_reservations: 'Przedpłata za rezerwacje',
                    system_will_charge: 'System pobierze płatność - liczba osób razy przedpłata za osobę.',
                    prepayment_per_person: 'Przedpłata za osobę',
                },
            },
        },
        room_division: {
            title: 'Podział lokalu na sale',
            form: {
                labels: {
                    room_layout: 'Plan lokalu',
                    add_image: 'Kliknij aby dodać lub przeciągnij i upuść',
                    image_format: 'PNG, WEBP, JPG',
                    image_size: 'max 2Mb',
                    image_resolution: 'optymalnie 650x650px',
                    room_division: 'Podział na sale',
                    add_room: 'Dodaj salę',
                },
            },
            room_list: {
                list_headers: {
                    room_division: 'Podział na sale',
                    add_room_button: 'Dodaj salę',
                },
                room_item: {
                    number_of_people: 'Liczba osób: {{count}} / {{maxCount}}',
                    reservation_time: 'Rezerwacja: {{hourStart}} - {{hourEnd}} / {{step}}min',
                    prepaid_per_person: 'Przedpłata za osobę: {{amount}} {{currency}}',
                    edit: 'Edytuj',
                    delete: 'Usuń',
                },
                add_room: {
                    title: 'Dodaj salę',
                    form: {
                        labels: {
                            name: 'Nazwa',
                            max_people: 'Maksymalna liczba osób w sali',
                            max_reservation_people: 'Maksymalna liczba osób w rezerwacji',
                            min_reservation_hour: 'Min godzina rozpocecia rezerwacji',
                            max_reservation_hour: 'Max godzina rozpocecia rezerwacji',
                            reservation_interval: 'Interwał rezerwacji',
                            prepayment_for_reservation: 'Przedpłata za rezerwacje',
                            system_will_charge: 'System pobierze płatność - liczba osób razy przedpłata za osobę.',
                            prepayment_per_person: 'Przedpłata za osobę',
                        },
                        go_premium: 'Go Premium',
                    },
                },
            },
        },
        form_presentation: {
            title: 'Wygląd Formularza',
            form: {
                labels: {
                    color: 'Kolor głowny',
                    background_image: 'Zdjęcie w tle',
                },
                remove_image: 'Usuń zdjęcie',
            },
        },
        form_title: {
            title: 'Nagłówek Formularza',
            form: {
                labels: {
                    avatar: 'Avatar',
                    title: 'Tytuł',
                    description: 'Krótki opis',
                    initial_information: 'Informacja na start',
                },
                remove_image: 'Usuń zdjęcie',
            },
        },
    },
    account_page: {
        title: 'Konto',
        profile: {
            title: 'Profil',
            email: 'E-mail',
            plan: 'Plan',
            plan_expiration: 'Ważność planu',
            change_premium: 'Go Premium',
            stripe: 'Stripe',
            onboarding: 'Przejdź OnBoarding',
            stripe_url: 'Przejdź do Stripe',
        },
        password_change: {
            title: 'Zmień hasło',
            form: {
                labels: {
                    old_password: 'Stare hasło',
                    new_password: 'Nowe hasło',
                    repeat_password: 'Powtórz nowe hasło',
                },
            },
            btn_save: 'Zapisz',
        },
        settings: {
            title: 'Ustawienia',
            form: {
                labels: {
                    language: 'Język',
                    languageList: {
                        pl: 'Polski',
                        en: 'Angielski',
                        uk: 'Ukraiński',
                    },
                    currency: 'Waluta',
                    currencyList: {
                        pln: 'PLN',
                        eur: 'EUR',
                        usd: 'USD',
                    },
                },
            },
        },
        delete_account: {
            title: 'Usuń konto',
            description: 'Usuń konto oraz wszystkie Twoje dane. Operacja jest nieodwracalna.',
            btn_delete: 'Usuń konto',
            delete_modal: {
                title: 'Usuń konto',
                subtitle: 'Usuń swoje konto oraz wszystkie dane z nim związane.',
                warning: 'Ta operacja jest nieodwracalna.',
                cannotBeDeleted: 'Nie możesz teraz usunąć konta.',
                buttons: {
                    delete_account: 'Usuń konto',
                    close_modal: 'Zamknij',
                },
            },
        },
        alerts: {
            change_locale_success: 'Język został zmieniony',
            change_password_success: 'Hasło zostało zmienione',
            profile_deleted: 'Konto zostało usunięte.',
            change_email_success: 'Adres e-mail został zmieniony',
        },
    },
    subscriptions_page: {
        title: 'Rezerwacje z klasą. Go Premium!',
        description: 'Usprawnij proces rezerwacji w swoim lokalu dzięki dodatkowym funkcjonalnościom Premium.',
    },
    footer: {
        copyright: `Reservation Spot {{date}} © All right reserved`,
    },
    maintenance: {
        page_not_found: 'Błąd - nie znaleziono strony',
    },
    alerts: {
        warning: 'Uwaga!',
        error: 'Błąd!',
        success: 'Sukces!',
        info: 'Informacja',
        authError: 'Nazwa użytkownika lub hasło są nieprawidłowe.',
        no_access_error: 'Nie masz uprawnień do przeglądania tej strony.',
        wrong_account_error: 'Nie możesz się zalogować do tej aplikacji za pomocą tego konta.',
        base_error: 'Wystąpił błąd, skontaktuj się z administratorem.',
        login_error: 'Wystąpił błąd przy logowaniu, spróbuj ponownie.',
        try_again: 'Spróbuj ponownie',
        unknown: 'Wystąpił nieznany błąd.',
        access_denied: 'Nie masz uprawnień.',
        payment_method_attached: 'Metoda płatności została pomyślnie dodana.',
        payment_method_deleted: 'Metoda płatności została usunięta.',
        account_not_confirmed: 'Konto nie zostało potwierdzone.',
        account_not_active: 'Konto nie jest aktywne.',
        account_deleted: 'Konto zostało usunięte.',
        account_is_invitation: 'Nieprawidłowe dane.',
        invalid_or_expired_token: 'Twój token wygasł lub jest nieprawidłowy.',
        expiredTokenLogout: 'Twoja sesja wygasła. Zostałeś wylogowany.',
        password_changed: 'Hasło zostało zmienione',
        bad_password: 'Niepoprawne hasło',
    },
    validation: {
        required: 'To pole jest wymagane',
        invalidEmail: 'Nieprawidłowy adres e-mail',
        invalidPassword: 'Nieprawidłowe hasło',
        invalidRepeatPassword: 'Hasła nie są takie same',
        invalidPhoneNumber: 'Nieprawidłowy numer telefonu',
        invalidUrl: 'Nieprawidłowy adres URL',
        invalidDate: 'Nieprawidłowa data',
        invalidImage: 'Nieprawidłowy format obrazu, dozwolone formaty to: {{extensions}}',
        invalidImageSize: 'Zbyt duży rozmiar obrazu, maksymalny rozmiar to {{size}} MB',
        invalidImageResolution: 'Zła rozdzielczość obrazu',
        invalidPlan: 'Nieprawidłowy plan',
        passwordPattern: 'Zbyt słabe hasło',
        passwordsMustMatch: 'Hasła muszą być takie same',
        mustAcceptTermsAndConditions: 'Musisz zaakceptować regulamin',
    },
};

export default en;
